import React, { PureComponent } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import './App.css';
import { WidthProvider, Responsive } from "react-grid-layout";
import _ from "lodash";

const API_ROOT = process.env.REACT_APP_API_ROOT || "http://localhost:3000";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

class Grid extends PureComponent {
  static defaultProps = {
    className: "layout",
    cols: { lg: 6, md: 6, sm: 4, xs: 2, xxs: 2 },
    rowHeight: 200
  };

  constructor(props) {
    super(props);

    this.state = {
      layouts: {},
      items: [],
      newCounter: 0
    };

    this.onAddItem = this.onAddItem.bind(this);
    this.onBreakpointChange = this.onBreakpointChange.bind(this);
  }

  async componentDidMount() {
    const resp = await fetch(`${API_ROOT}/contents`);
    const data = await resp.json();
    this.setState({
      items: data.contents.items,
      layouts: data.contents.layouts,
    });
  }

  createElement(el) {
    const removeStyle = {
      position: "absolute",
      right: "0",
      top: "1px",
      cursor: "pointer",
      textDecoration: "none",
      fontWeight: "bold",
      backgroundColor: "white",
      lineHeight: 1,
      padding: "2px 4px"
    };

    const contentStyle = {
      display: "block",
      width: "100%",
      height: "100%",
      backgroundImage: `url(${el.image})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% auto',
    }

    return (
      <div key={el.i}>
        <span
          className="remove"
          style={removeStyle}
          onClick={this.onRemoveItem.bind(this, el.i)}
        >
          x
        </span>
        <a draggable="false" href={el.link} style={contentStyle} target="_blank" rel="noopener noreferrer" onClick={this.verifyNotDraggingOrResize.bind(this)}>&nbsp;</a>
      </div>
    );
  }

  onAddItem() {
    /*eslint no-console: 0*/
    console.log("adding", "n" + this.state.newCounter);
    this.setState({
      // Add a new item. It must have a unique key!
      items: this.state.items.concat({
        i: "n" + this.state.newCounter,
        x: (this.state.items.length * 2) % (this.state.cols || 12),
        y: Infinity, // puts it at the bottom
        w: 1,
        h: 1,
        isResizable: true, 
      }),
      // Increment the counter to ensure key is always unique.
      newCounter: this.state.newCounter + 1
    });
  }

  // We're using the cols coming back from this to calculate where to add new items.
  onBreakpointChange(breakpoint, cols) {
    this.setState({
      breakpoint: breakpoint,
      cols: cols
    });
  }

  onLayoutChange(layout, layouts) {
    saveToServer(this.state.items, layouts);
    this.setState({ layouts });
  }

  async onRemoveItem(i) {
    console.log("removing", i);
    await fetch(`${API_ROOT}/contents/delete/${i}`, { method: 'POST' });
    this.setState({ items: _.reject(this.state.items, { i: i }) });
  }

  verifyNotDraggingOrResize = (e) => {
    if (this.isDragging || this.isResizing) {
      e.stopPropagation();
      e.preventDefault();
    }
  }

  onDrag = (e) => {
      this.isDragging = true;
  }
  onDragStop = (e) => {
      // HACK: add some delay otherwise a click event is sent
      setTimeout((obj) => { obj.isDragging = false }, 200, this)
  }
  onResizeStart = (e) => {
      this.isResizing = true;
  }
  onResizeStop = (e) => {
      // HACK: add some delay otherwise a click event is sent
      setTimeout((obj) => { this.isResizing = false }, 200, this)
  }

  render() {
    return (
      <div>
        {/*<div className="toolbar">
          <Container>
            <Button onClick={this.onAddItem}>Add Item</Button>
          </Container>
          </div>*/}

        <Container>
          <ResponsiveReactGridLayout
            onDrag={this.onDrag.bind(this)}
            onDragStop={this.onDragStop.bind(this)}
            onResizeStart={this.onResizeStart.bind(this)}
            onResizeStop={this.onResizeStop.bind(this)}
            preventCollision={false}
            compactType={null}            
            onLayoutChange={this.onLayoutChange.bind(this)}
            layouts={this.state.layouts}
            onBreakpointChange={this.onBreakpointChange.bind(this)}

            {...this.props}
          >
            {_.map(this.state.items, el => this.createElement(el))}
          </ResponsiveReactGridLayout>
        </Container>
      </div>
    );
  }
}

async function saveToServer(items, layouts) {
  return fetch(`${API_ROOT}/contents/save_layout`, {
    method: 'POST',
    body: JSON.stringify({
      items,
      layouts,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export default Grid;
